import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@pw-utils/services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class TermsGuard  {
  constructor(private router: Router, private authService: AuthService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.authService.isTermsAccepted() || this.router.parseUrl(route.data?.termsPath);
  }
}
